function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

export const showLocalPushNotification = (title, body) => {
    setTimeout(() => {
        navigator.serviceWorker.ready.then(registration => {
            registration.showNotification(title, {
                body: body,
                icon: 'https://w3.el-ms.tech/gera_img/?letra=c&resolucao=32',
                vibrate: [100, 50, 100],
                data: {
                    dateOfArrival: Date.now(),
                    primaryKey: 1
                },
            });
        }, 5000);
    });
};

export const registerNotificationInWebServer = (urlWebServer, publicKey, token) => {
    const applicationServerKey = urlBase64ToUint8Array(publicKey);
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerKey,
            }).then(subscription => {
                console.log('sub', subscription);
                fetch(urlWebServer, {
                    method: 'POST',
                    body: JSON.stringify(subscription),
                    headers: {
                        'content-type': 'application/json',
                        'authorization': 'bearer: ' + token.replace(/['"]+/g, '')
                    }
                }).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error);
                });
            });
        });
    }
}

export const requestNotificationPermission = () => {
    if ('Notification' in window) {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Permissão de notificação concedida');
            } else {
                console.log('Permissão de notificação negada');
            }
        });
    }
}