export const filterRemoveTicket = function (ticket, user) {
    let condition = true;
    const queuesThisUser = user?.queues?.map(queue => queue.id);
    if (user?.profile === 'admin' || user?.isManager) {
        return true;
    }
    if (ticket.status === "nps") {
        return false;
    }
    if (ticket.userId !== null && ticket.userId !== user?.id) {
        if (ticket.queueId !== null && !queuesThisUser.includes(ticket.queueId)) {
            return false;
        }
        return true;
    }
    if (ticket.queueId === null && ticket.userId === null) {
        return true;
    }
    return condition;
};