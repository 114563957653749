import axios from 'axios';
import { getBackendUrl } from './config';

async function subscribe(serviceWorkerReg) {
  let token = localStorage.getItem('token');
  if (!token) {
    token = '';
  }
  let subscription = await serviceWorkerReg.pushManager.getSubscription();
  console.log('SUB -> ', { subscription });
  axios.post(getBackendUrl() + '/user-push/register', subscription, {
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'bearer: ' + token.replace(/['"]+/g, ''),
    },
  }).then((response) => {
    console.log('subscribe', response.data);
    sessionStorage.setItem('push', response.data);
  }).catch((error) => {
    sessionStorage.setItem('pushe', error);
    console.error('subscribe error', { error });
  });
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export {subscribe};
