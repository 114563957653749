import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";
import { BiSend, BiTransfer } from 'react-icons/bi'

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import TransferTicketModal from "../TransferTicketModal";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ 
	ticket, 
	showSelectMessageCheckbox, 
	selectedMessages, 
	forwardMessageModalOpen,
	setForwardMessageModalOpen 
}) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const { whatsApps } = useContext(WhatsAppsContext);
	const currentWhatsapp = whatsApps.find(whats => whats.id === ticket.whatsappId);


	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleOpenModalForward = () => {
		if (selectedMessages.length === 0) {
			toastError({response: {data: {message: "Nenhuma mensagem selecionada"}}});
			return;
		}
		setForwardMessageModalOpen(true);
	}

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);

		if (status === "nps") {
			status = ticket?.contact?.disableBot ? "closed" : status;
		}

		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	const handleNewTicket = async contactId => {
		setLoading(true);
		if (isNaN(contactId)) {
			toastError('Id do contato inválido: ' + contactId);
			return;
		}
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user.id,
				status: "open",
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
	};



	return (
		<Fragment>

			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={() => setTransferTicketModalOpen(false)}
				ticketid={ticket.id}
				ticketWhatsappId={ticket.whatsappId}
			/>

			<div className={classes.actionButtons}>
				{ticket.status === "closed" && (
					<ButtonWithSpinner
						loading={loading}
						size="small"
						onClick={e => handleNewTicket(ticket?.contact?.id)}
					>
						Nova mensagem
					</ButtonWithSpinner>
				)}
				{ticket.status === "open" && (
					<>
						{/* <ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner> */}

						{!showSelectMessageCheckbox ? (
							<>
								<ButtonWithSpinner
									loading={loading}
									startIcon={<BiTransfer />}
									size="small"
									onClick={e => setTransferTicketModalOpen(true)}
								>
									{i18n.t("ticketOptionsMenu.transfer")}
								</ButtonWithSpinner>
								<ButtonWithSpinner
									loading={loading}
									size="small"
									variant="contained"
									color="primary"
									onClick={e => handleUpdateTicketStatus(null, !ticket?.isGroup ? currentWhatsapp?.useNPS ? "nps" : 'closed' : 'closed', user.id)}
								// onClick={e => handleUpdateTicketStatus(e, currentWhatsapp?.useNPS ? "nps" : 'closed', user.id)}

								// onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
								>
									{i18n.t("messagesList.header.buttons.resolve")}
								</ButtonWithSpinner>
								<IconButton onClick={handleOpenTicketOptionsMenu}>
									<MoreVert />
								</IconButton>
								<TicketOptionsMenu
									ticket={ticket}
									anchorEl={anchorEl}
									menuOpen={ticketOptionsMenuOpen}
									handleClose={handleCloseTicketOptionsMenu}
								/>
						</>
						): 
							<ButtonWithSpinner
								loading={loading}
								startIcon={<BiSend />}
								size="small"
								onClick={handleOpenModalForward}
							>
								Encaminhar
							</ButtonWithSpinner>
						}
					</>
				)}
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.accept")}
					</ButtonWithSpinner>
				)}
			</div>
		</Fragment>
	);
};

export default TicketActionButtons;
