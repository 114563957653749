import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import App from "./App";


ReactDOM.render(
	<CssBaseline>
		<App />
	</CssBaseline>,
	document.getElementById("root")
);

// serviceWorkerRegistration.register();
if ('serviceWorker' in navigator) {
    if (navigator.serviceWorker.controller) {
    } else {
        navigator.serviceWorker
            .register('/sw.js', {
                scope: '/' 
            })
            .then(function (reg) {
            });
    }
}

// async function subscribe(serviceWorkerReg) {
// 	let subscription = await serviceWorkerReg.pushManager.getSubscription();
// 	  subscription = await serviceWorkerReg.pushManager.subscribe({
// 		userVisibleOnly: true,
// 		applicationServerKey: 'BCW9X8yy3xapfvLuhnmobSukAdV4hJKB5F4QgK09Ww69SCRbbUAV80qkPTpRETO-1ZAHuUEvfX0hxlX1XfVG364',
// 	  });
// 	  console.log('SUB -> ', {subscription});
// }

// navigator.serviceWorker.ready.then((registration) => {
// 	subscribe(registration, true);
// });

