function getConfig(name, defaultValue=null) {
    // If inside a docker container, use window.ENV
    if( window.ENV !== undefined ) {
        return window.ENV[name] || defaultValue;
    }

    return process.env[name] || defaultValue;
}

export function getBackendUrl() {
    // return 'https://apiwhats-teste.edsonluciano.com';
    return getConfig('REACT_APP_BACKEND_URL');
}

export function getHoursCloseTicketsAuto() {
    return getConfig('REACT_APP_HOURS_CLOSE_TICKETS_AUTO');
}

export function showOnlyMyTickets() {
    let config = getConfig('REACT_APP_SHOW_ONLY_MY_TICKETS');
    if (config == 'true') {
        return true;
    }
    return false;
}

export function limitUsers() {
    let config = getConfig('REACT_APP_LIMIT_USERS');
    if (config === null) {
        return 10;
    }
    return config;
}

export function port() {
    let config = getConfig('REACT_APP_PORT_HTTP');
    if (config === null) {
        return 3333;
    }
    return config;
}

export function getAppName() {
    return getConfig('REACT_APP_NAME', '');
}