import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MenuProps } from './MenuProps';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, FormControl, InputLabel, Select, Stack, TextField } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import './button.css';
import { ButtonBase } from '@material-ui/core';
import { subscribe } from '../../util';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: true,
      text: 'Tickets',
      position: 'left',
    },
    datalabels: {
      display: true,
      anchor: 'start',
      offset: -30,
      align: "start",
      color: "#fff",
      textStrokeColor: "#000",
      textStrokeWidth: 2,
      font: {
        size: 20,
        weight: "bold"

      },
    }
  },
};

export const ChatsUser = () => {

  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [ticketsData, setTicketsData] = useState({
    data: [],
  });

  useEffect(() => {
    handleGetTicketsInformation();
  }, []);

  const dataCharts = {

    labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => item.nome),
    datasets: [
      {
        label: 'Dataset 1',
        data: ticketsData?.data.length > 0 && ticketsData?.data.map((item, index) => {
          return item.quantidade
          // return item.npsGeneral
        }),
        backgroundColor: '#8dc71b',
      },

    ],
  };

  const handleGetTicketsInformation = async () => {
    try {

      const { data } = await api.get(`/dashboard/ticketsUsers?initialDate=${format(initialDate, 'yyyy-MM-dd')}&finalDate=${format(finalDate, 'yyyy-MM-dd')}`);
      setTicketsData(data);
    } catch (error) {
      toast.error('Erro ao buscar informações dos tickets');
    }
  }

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Total de atendimentos por usuario
      </Typography>
      <Button className="buttonHover" onClick={() => {
        navigator.serviceWorker.ready.then((registration) => {
          subscribe(registration);
        });
      }}>Registrar</Button>

      <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ my: 2, }} >

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
          <DatePicker
            value={initialDate}
            onChange={(newValue) => { setInitialDate(newValue) }}
            label="Data inicial"
            renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}

          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
          <DatePicker
            value={finalDate}
            onChange={(newValue) => { setFinalDate(newValue) }}
            label="Data final"
            renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
          />
        </LocalizationProvider>

        <Button className="buttonHover" onClick={handleGetTicketsInformation} variant='contained'>Filtrar</Button>

      </Stack>
      <Bar options={options} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px', }} />
    </>
  );
}